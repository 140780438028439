import {VuexModule, getModule, Mutation, Action, MutationAction, Module} from "vuex-module-decorators"
import { AdminNotice } from "@/store/models/adminNotice"
import api from "@/store/api/adminNotice"
import store from "@/store"


@Module({
    namespaced: true,
    name: "AdminNotice",
    store,
    dynamic: true
})
class AdminNoticeModule extends VuexModule{

    noticeList: AdminNotice[] = [] 

    @Mutation
    setNoticeList(notice: Array<AdminNotice>){
        this.noticeList = notice
    }

    @Action({ commit: 'setNoticeList'})
    async getAdminNotice(){
        const data = await api.getAdminNotice()
        return data
    }

    @Action
    async patchAdminNotice(obj: any) {
        await api.patchAdminNotice(obj)  
    }
}
export default getModule(AdminNoticeModule)