import { getApi, patchApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import { AdminNotice } from "../models/adminNotice";

export default new (class AdminNoticeAPI {
  public async getAdminNotice(): Promise<AdminNotice> {
    const action = Action.adminNotice;
    const response = await getApi(action);

    return response as AdminNotice;
  }

  public async patchAdminNotice(obj: any): Promise<AdminNotice> {
    const action = Action.adminNotice + `${obj.id}/`;
    const response = await patchApi(action, obj);

    return response as AdminNotice;
  }
})  