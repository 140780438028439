

































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import helpers from "@/utils/helpers";
import { AdminNotice } from "@/store/models/adminNotice";
import AdminNoticeModule from "@/store/modules/adminNotice";

@Component({
  components: {},
})
export default class Index extends Vue {
  adminNotice: AdminNotice[] = [];
  colSpan = 8;

  toMomentDate(date: string) {
    return helpers.toMomentDate(date);
  }

  get showNotice() {
    return this.hasRoles([
      "admin",
      "dotm_staff",
      "vehicle_owner",
      "gps_service_provider",
    ]);
  }

  async created() {
    if (
      this.hasRoles([
        "admin",
        "dotm_staff",
        "vehicle_owner",
        "gps_service_provider",
      ])
    )
      await AdminNoticeModule.getAdminNotice();
    this.adminNotice = AdminNoticeModule.noticeList;
    if (!this.showNotice) {
      this.colSpan = 6;
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }
  get Role() {
    const profile = helpers.getUserProfile();
    if (!profile) return false;
    return profile.Role;
  }

  get id() {
    const profile = helpers.getUserProfile();
    if (!profile) return false;
    return profile.id;
  }

  hasRoles(roles: string[]) {
    return helpers.hasRoles(roles);
  }

  get approvedUser() {
    const profile = helpers.getUserProfile();
    if (profile && profile.userDetail && profile.userDetail.profile) {
      return profile.userDetail.profile.approvalStatus == "Approved"
        ? true
        : false;
    }
    return false;
  }

  async visitNotice(e: any) {
    e.isVisited = true;
    this.$router.push(e.redirectData);
    await AdminNoticeModule.patchAdminNotice(e);
  }

  async deleteNotice(e: any) {
    e.isActive = false;
    await AdminNoticeModule.patchAdminNotice(e);
    await AdminNoticeModule.getAdminNotice();
    this.adminNotice = AdminNoticeModule.noticeList;
  }
}
